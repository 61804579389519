import React from "react";
import { ConfigProvider } from "antd";
import themeData from './theme.json'

const withTheme = (node: JSX.Element) => (
  <>
    <ConfigProvider
      theme={{
        "token": {
          "fontFamily": "Arial,Helvetica,sans-serif",
          "colorPrimary": "#d90b17",
          "fontSizeXL": 24,
          "fontSizeHeading1": 24,
          "fontSizeHeading2": 18,
          "fontSizeHeading3": 16,
          "fontSizeHeading4": 14,
          "fontSizeHeading5": 12,
          "lineHeightHeading2": 1.25,
          "lineHeightHeading1": 1,
          "lineHeightHeading3": 1.3,
          "borderRadius": 8,
          "borderRadiusSM": 0,
          "borderRadiusXS": 0,
          "borderRadiusLG": 8,
          "colorError": "#dddddd",
          "colorTextBase": "#333333",
          "colorText": "#333333",
          "colorTextSecondary": "#666666",
          "colorPrimaryBorderHover": "#f30e1c",
          "colorPrimaryHover": "##f30e1c",
          "colorPrimaryActive": " #d90b17",
          "colorPrimaryText": " #d90b17",
          "colorPrimaryTextHover": "#f30e1c",
          "colorPrimaryTextActive": " #d90b17",
          "colorSuccess": "#30ca6f",
          "colorInfo": "#d90b17"
        },
        "components": {
          "Button": {
            "borderRadius": 40,
            "colorBorder": "#dddddd",
            "controlHeight": 40,
            "colorText": "#333333",
            "fontSize": 16,
            "fontSizeLG": 18,
            "lineHeight": 1.35,
            "paddingContentHorizontal": 40,
            "paddingXS": 24,
            "colorLink": "#319eff",
            "colorLinkHover": "#006be3",
            "colorLinkActive": "#319eff",
            "borderRadiusLG": 40,
            "colorPrimaryHover": "#f30e1c",
            "colorPrimaryActive": "#d90b17",
            "colorPrimaryBorder": "#d90b17",
            "colorTextDisabled": "#bbbbbb"
          },
          "Checkbox":{
            "colorBorder": "#1058D7",
            "colorPrimary": "#1058D7",
            "fontSize": 16,
            "controlHeight": 40,
            "paddingContentHorizontal": 24,
            "borderRadiusLG": 8,
          },
          "Typography": {
            "fontWeightStrong": 600,
            "colorSuccess": "#30ca6f",
            "colorLink": "#319eff",
            "colorLinkActive": "#319eff",
            "colorLinkHover": "#006be3",
            "colorTextHeading": "#333333",
            "controlHeight": 40,
            "controlPaddingHorizontal": 24,
            "colorWarning": "#faad14",
            "colorError": "#dddddd",
            "colorErrorActive": "#b8b8b8",
            "colorErrorHover": "#ebebeb",
            "colorPrimaryHover": "#d70b17",
            "colorText": "#333333",
            "colorTextDisabled": "#bbbbbb"
          },
          "Divider": {
            "colorText": "#333333",
            "colorTextHeading": "#333333",
            "colorSplit": "rgba(5, 5, 5, 0.06)"
          },
          "Breadcrumb": {
            "colorBgTextHover": "rgba(0, 0, 0, 0)",
            "colorText": "#333333",
            "fontSize": 16,
            "paddingXXS": 8
          },
          "Dropdown": {
            "colorText": "#333333",
            "colorTextDisabled": "#dddddd",
            "controlHeight": 32,
            "controlPaddingHorizontal": 16,
            "colorError": "#dddddd",
            "colorPrimary": "#d90b17",
            "borderRadiusLG": 0
          },
          "Menu": {},
          "Pagination": {
            "borderRadius": 0,
            "borderRadiusLG": 0,
            "borderRadiusSM": 0
          },
          "Input": {
            "borderRadius": 0,
            "borderRadiusLG": 0,
            "colorBorder": "#1058D7",
            "colorErrorBorderHover": "#f30e1c",
            "colorError": "#dddddd",
            "colorPrimary": "rgb(51,51,51)",
            "colorPrimaryActive": "rgb(51,51,51)",
            "colorPrimaryHover": "rgb(51,51,51)",
            "controlOutlineWidth": 0,
            "colorText": "#333333",
            "colorTextDisabled": "#bbbbbb",
            "colorTextPlaceholder": "#bbbbbb",
            "colorTextQuaternary": "#bbbbbb",
            "controlPaddingHorizontal": 8,
            "fontSize": 16,
            "fontSizeIcon": 18,
            "fontSizeLG": 18,
            "paddingSM": 8,
            "paddingLG": 16,
            "controlHeight": 40
          },
          "InputNumber": {
            "borderRadius": 0,
            "borderRadiusLG": 0,
            "colorPrimary": "#d90b17",
            "colorPrimaryHover": "#f30e1c",
            "controlOutlineWidth": 0,
            "colorErrorBorderHover": "##f7f7f7",
            "colorError": "#dddddd",
            "colorBorder": "#dddddd",
            "colorText": "#333333",
            "colorTextDisabled": "#bbbbbb",
            "controlPaddingHorizontal": 16,
            "fontSize": 16,
            "paddingSM": 16
          },
          "Mentions": {
            "borderRadius": 0,
            "borderRadiusLG": 0,
            "colorBorder": "#dddddd",
            "colorErrorBorderHover": "#f7f7f7",
            "colorPrimaryHover": "#f30e1c",
            "colorError": "#dddddd",
            "colorText": "#333333",
            "colorTextDisabled": "#bbbbbb",
            "fontSize": 16,
            "controlHeight": 32,
            "paddingSM": 16,
            "controlItemBgHover": "#f5f5f5",
            "controlPaddingHorizontal": 8,
            "controlOutlineWidth": 2,
            "colorTextPlaceholder": "rgba(0, 0, 0, 0.25)"
          },
          "Select": {
            "borderRadius": 0,
            "borderRadiusLG": 0,
            "colorErrorHover": "#ebebeb",
            "colorIcon": "#333333",
            "colorBorder": "#1058D7",
            "colorPrimary": "#d90b17",
            "colorPrimaryHover": "#1058D7",
            "colorTextDisabled": "#bbbbbb",
            "controlItemBgActive": "#ffeae6",
            "paddingSM": 8,
            "fontSizeIcon": 18,
            "fontSizeLG": 16,
            "fontSizeSM": 14,
            "controlHeight": 40,
            "controlOutlineWidth": 2,
            "controlPaddingHorizontal": 8,
            "fontWeightStrong": 600
          },
          "Avatar": {
            "borderRadius": 0,
            "borderRadiusLG": 0
          },
          "Table": {
            "colorBorderSecondary": "#f5f5f5",
            "colorIcon": "#333333",
            "colorIconHover": "#222222",
            "colorLink": "#319eff",
            "colorLinkActive": "#319eff",
            "colorLinkHover": "#006be3",
            "fontSizeIcon": 18,
            "colorTextHeading": "#333333",
            "colorText": "#333333",
            "colorPrimary": "#d90b17",
            "controlItemBgActive": "#fff",
            "controlItemBgHover": "#f5f5f5"
          },
          "Tag": {
            "borderRadiusSM": 4,
            "colorPrimaryActive": "#d90b17",
            "colorPrimaryHover": "#f30e1c",
            "colorInfo": "#319eff"
          },
          "Timeline": {
            "colorSuccess": "#30ca6f",
            "colorError": "#dddddd"
          },
          "Alert": {
            "colorSuccess": "#30ca6f",
            "colorWarning": "#faad14",
            "colorError": "#dddddd",
            "colorInfo": "#319eff",
            "colorInfoBorder": "#eaf5ff",
            "colorErrorBorder": "#fff2f0",
            "colorInfoBg": "#eaf5ff",
            "colorIconHover": "#222222",
            "colorIcon": "#333333",
            "colorSuccessBorder": "#f6ffed",
            "colorWarningBorder": "#fffbe6",
            "fontSizeIcon": 14,
            "fontSizeLG": 24,
            "marginSM": 16
          },
          "Popconfirm": {
            "colorWarning": "#faad14"
          },
          "Progress": {
            "colorInfo": "#d90b17",
            "colorError": "#dddddd",
            "colorSuccess": "#30ca6f",
            "colorFillSecondary": "#f5f5f5"
          },
          "Result": {
            "colorSuccess": "#30ca6f",
            "colorInfo": "#319eff",
            "colorError": "#dddddd",
            "colorFillAlter": "#f5f5f5",
            "colorTextHeading": "#333333",
            "colorTextDescription": "#999999"
          },
          "Tree": {
            "colorPrimaryHover": "#f30e1c",
            "colorPrimaryBorder": "#d90b17",
            "colorBorder": "#dddddd",
            "colorBgContainerDisabled": "#f5f5f5",
            "colorTextDisabled": "#bbbbbb"
          },
          "Tabs": {
            "colorPrimaryActive": "#d90b17",
            "colorPrimaryHover": "#f30e1c",
            "colorPrimaryBorder": "#d90b17",
            "colorBorder": "#dddddd",
            "fontSize": 18,
            "controlHeight": 40,
            "padding": 24
          },
          "Form": {
            "colorError": "#d90b17"
          },
          "DatePicker": {
            "colorLink": "#319eff",
            "colorLinkActive": "#319eff",
            "colorLinkHover": "#006be3",
            "borderRadius": 0,
            "controlHeight": 40
          },
          "Transfer": {
            "colorLinkHover": "#006be3"
          },
          "Calendar": {
            "colorLinkHover": "#006be3",
            "colorLink": "#319eff",
            "colorLinkActive": "#319eff"
          },
          "Message": {
            "colorInfo": "#319eff"
          }
        }
      }}
    >
      {node}
    </ConfigProvider>
  </>
)

export default withTheme;